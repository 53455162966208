import { size, colors } from '@everlywell/leaves'
import styled from 'styled-components'

const checkboxIcon = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAZCAYAAABU+vysAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGMSURBVHgBzdZNTsJAFAfw96ZEFwKZpQs13RC3LN1JdyYa4w2AI3ABGTyBNwBOIH5g4qoegT0bArpv2DPPThMQI9My/YL/oukkr6+/TtOZAuxJLMg5pw/XXe6cD8qXFT7/HH/sBKIQCNhQ54h4UXYq9twdP+cKWUcs44+rSwxCxuGixktYfEKEmr6KWgXIGFHGI9d/9GpYHUnmZTYjvwgMRxD1Z+1hg8EOEZKgoxDqPPUZMUF8td/EcozrDUqs2AUCLkm2vsX7CAxzLK7sA2a5flsbDBAryIYG3kJKxwSTBKHCNA24xZh7Im5qkAMigBxioatpwBkD96xzW4eMEQEEooKyp8OkhQggSIWm32gChpg0EcEt1MEWd7ZkC78p2aHVxBrT9ks/bcQKYoSR0AFG9SiE2j+m98NH2DJ/FrStMREhic2ZeO2ZXPNvZU2KiYPYCEmCiYvQQuJgkiBUtOvIRAwmTFpO5KcN6CVFBF2iCsJnBj2F9dHGG6QxRI9JD6Gy1Y/R6jURjLJAqPwAeR3pvn4d6BQAAAAASUVORK5CYII='

const CheckboxLabel = styled.label`
  display: inline-flex;
  gap: ${size.md}px;
  align-items: flex-start;
  font-size: 18px;
  font-weight: 300;
`

const CheckboxLabelText = styled.div`
  font-weight: inherit;

  strong {
    font-weight: 600;
  }
`

const CheckboxInput = styled.input`
  appearance: none;
  display: inline-block;
  min-width: ${size.lg}px;
  width: ${size.lg}px;
  height: ${size.lg}px;
  border: 1px solid rgba(113, 172, 133, 50%);
  box-shadow: 4px 4px 15px -5px rgba(0, 0, 0, 10%);
  border-radius: 3px;
  background-color: ${colors.white};
  transition: border-color 300ms;

  &:hover,
  &:checked {
    border-color: ${colors.green4};
  }

  &:checked {
    background-image: url(${checkboxIcon});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: ${size.md}px;
  }
`

export { CheckboxLabel, CheckboxLabelText, CheckboxInput }
