import { size, colors, H3, typography, mediaQueries } from '@everlywell/leaves';
import styled, { css } from 'styled-components';

const Card = styled.div`
  max-width: 640px;
  margin: ${size.xl4}px auto;
  padding: ${size.xl2}px;
  border-radius: 2px;
  box-shadow: 0px 2px 32px -5px rgba(170, 169, 172, 0.24);
  background-color: ${colors.white};
  border-radius: 8px;

  h3 + p {
    margin-top: ${size.xs1}px;
  }

  ${mediaQueries.forTabletVerticalUp} {
    min-width: 640px;
  }

  ${mediaQueries.forTabletVerticalDown} {
    width: 100%;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
  }
`;

const HeaderAndFooterCommonStyle = css`
  text-align: center;

  > p {
    margin: 0;
  }
`;

const CardHeading = styled(H3)`
  margin: 0;
`;

const CardHeader = styled.header`
  ${HeaderAndFooterCommonStyle};
`;

const CardFooter = styled.footer`
  ${typography.captionText}
  ${HeaderAndFooterCommonStyle};
  font-weight: ${typography.weight.light};
`;

const CardFooterLink = styled.a`
  ${typography.captionText}
  color: ${colors.green5};
  font-weight: ${typography.weight.regular};
  text-decoration: none;
  &:hover,
  &:focus {
    color: ${colors.teal5};
  }
`;

const ProgressBarContainer = styled.div`
  margin-top: ${size.xl1}px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: ${size.xl2}px;
  max-width: ${Number(size.xl8) * 2}px;
`;

export {
  Card,
  CardHeading,
  CardHeader,
  CardFooter,
  CardFooterLink,
  ProgressBarContainer,
};
