import { useContext } from 'react';

import { WizardContext } from './context';

const useWizard = () => {
  const { state, dispatch } = useContext(WizardContext);

  return {
    state,
    dispatch,
  };
};

export { useWizard };
