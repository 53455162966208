import React from 'react'

import * as S from './styles'

interface Props {
  labelText: string;
  children: React.ReactNode;
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
}

const Checkbox: React.FC<Props> = ({ labelText, children, labelProps, ...rest }: Props) => {
  return (
    <S.CheckboxLabel {...labelProps}>
      <S.CheckboxInput type="checkbox" {...rest} />
      <S.CheckboxLabelText>{labelText ?? children}</S.CheckboxLabelText>
    </S.CheckboxLabel>
  )
}

export default Checkbox
