import mixpanel from 'mixpanel-browser';

let initialized = false;

const init = () =>
  new Promise<void>((resolve, reject) => {
    if (process.env.GATSBY_MIXPANEL_PROJECT_TOKEN) {
      if (!initialized) {
        mixpanel.init(process.env.GATSBY_MIXPANEL_PROJECT_TOKEN);
        initialized = true;
        // @ts-ignore
        window.mixpanel = mixpanel;
      }

      return resolve();
    }

    return reject();
  });

export const pageName = () => {
  const pageNameElements = document.getElementsByClassName(
    'hidden-page-name-element',
  );

  // Get the last page name element on page, to support overriding in nested components
  // Note: pop not supported on HTMLCollection
  const pageNameElement = pageNameElements[pageNameElements.length - 1];

  return pageNameElement
    ? pageNameElement.getAttribute('data-page-name')
    : 'Any';
};

const getPortalType = () => {
  const elem = document.querySelector('[data-portal-type]') as HTMLElement;

  return elem?.dataset?.portalType;
};

export const track = (eventName: string, data: object) => {
  return init()
    .then(() => {
      mixpanel.track(
        eventName,
        Object.assign(
          {},
          {
            page_name: pageName(),
            category: 'Opt In Portal',
            enterprise_partner_id: process.env.GATSBY_ENTERPRISE_PARTNER_ID,
            portal_type: getPortalType(),
          },
          data,
        ),
      );
      return Promise.resolve();
    })
    .catch(() => Promise.resolve());
};

export default {
  track,
};
