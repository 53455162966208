export const IS_PROD_ENV = process.env.GATSBY_REACT_APP_ENV === 'prod';
export const APP_ROOT = IS_PROD_ENV
  ? 'https://www.everlywell.com'
  : 'https://qa.everlywell.com';
export const EH_ROOT = 'https://www.everlyhealth.com';
export const SUPPORT_ROOT = 'https://everlywell.force.com/membersupport/s/';
export const HELP_FORM_ADDRESS = 'membersupport.everlywell.com';
export const REGISTER_KIT_LINK = IS_PROD_ENV
  ? 'https://results.everlywell.com/register'
  : 'https://results-qa.everlywell.com/register';
export const TC_LINK = `${APP_ROOT}/terms-and-conditions/`;
export const EF_MEMBERS_LINK = `${APP_ROOT}/members`;
export const EF_MEMBERS_SUPPORT_LINK = `https://memberfaqs.everlywell.com/`;
export const LEARN_THE_SCIENCE_LINK = IS_PROD_ENV
  ? 'https://www.everlywell.com/science/'
  : 'https://qa.everlywell.com/science/';
export const EF_MEMBERS_SUPPORT_EMAIL = `membercare@everlywell.com`;
export const EF_MEMBERS_SUPPORT_PHONE_NUMBER = `855-923-2678`;
export const EF_MEMBERS_SUPPORT_FORM = `membersupport.everlywell.com`;
export const CONSENT_AND_NOTICE_LINK = `${APP_ROOT}/members/consentandnotice`;
export const PRIVACY_POLICY_LINK = `${APP_ROOT}/privacy-policy`;

export const US_STATES = [
  { value: 'Select State', id: 'default-select', text: 'Select State' },
  { value: 'AL', id: 'AL', text: 'Alabama' },
  { value: 'AK', id: 'AK', text: 'Alaska' },
  { value: 'AZ', id: 'AZ', text: 'Arizona' },
  { value: 'AR', id: 'AR', text: 'Arkansas' },
  { value: 'CA', id: 'CA', text: 'California' },
  { value: 'CO', id: 'CO', text: 'Colorado' },
  { value: 'CT', id: 'CT', text: 'Connecticut' },
  { value: 'DE', id: 'DE', text: 'Delaware' },
  { value: 'DC', id: 'DC', text: 'District Of Columbia' },
  { value: 'FL', id: 'FL', text: 'Florida' },
  { value: 'GA', id: 'GA', text: 'Georgia' },
  { value: 'HI', id: 'HI', text: 'Hawaii' },
  { value: 'ID', id: 'ID', text: 'Idaho' },
  { value: 'IL', id: 'IL', text: 'Illinois' },
  { value: 'IN', id: 'IN', text: 'Indiana' },
  { value: 'IA', id: 'IA', text: 'Iowa' },
  { value: 'KS', id: 'KS', text: 'Kansas' },
  { value: 'KY', id: 'KY', text: 'Kentucky' },
  { value: 'LA', id: 'LA', text: 'Louisiana' },
  { value: 'ME', id: 'ME', text: 'Maine' },
  { value: 'MD', id: 'MD', text: 'Maryland' },
  { value: 'MA', id: 'MA', text: 'Massachusetts' },
  { value: 'MI', id: 'MI', text: 'Michigan' },
  { value: 'MN', id: 'MN', text: 'Minnesota' },
  { value: 'MS', id: 'MS', text: 'Mississippi' },
  { value: 'MO', id: 'MO', text: 'Missouri' },
  { value: 'MT', id: 'MT', text: 'Montana' },
  { value: 'NE', id: 'NE', text: 'Nebraska' },
  { value: 'NV', id: 'NV', text: 'Nevada' },
  { value: 'NH', id: 'NH', text: 'New Hampshire' },
  { value: 'NJ', id: 'NJ', text: 'New Jersey' },
  { value: 'NM', id: 'NM', text: 'New Mexico' },
  { value: 'NY', id: 'NY', text: 'New York' },
  { value: 'NC', id: 'NC', text: 'North Carolina' },
  { value: 'ND', id: 'ND', text: 'North Dakota' },
  { value: 'OH', id: 'OH', text: 'Ohio' },
  { value: 'OK', id: 'OK', text: 'Oklahoma' },
  { value: 'OR', id: 'OR', text: 'Oregon' },
  { value: 'PA', id: 'PA', text: 'Pennsylvania' },
  { value: 'RI', id: 'RI', text: 'Rhode Island' },
  { value: 'SC', id: 'SC', text: 'South Carolina' },
  { value: 'SD', id: 'SD', text: 'South Dakota' },
  { value: 'TN', id: 'TN', text: 'Tennessee' },
  { value: 'TX', id: 'TX', text: 'Texas' },
  { value: 'UT', id: 'UT', text: 'Utah' },
  { value: 'VT', id: 'VT', text: 'Vermont' },
  { value: 'VA', id: 'VA', text: 'Virginia' },
  { value: 'WA', id: 'WA', text: 'Washington' },
  { value: 'WV', id: 'WV', text: 'West Virginia' },
  { value: 'WI', id: 'WI', text: 'Wisconsin' },
  { value: 'WY', id: 'WY', text: 'Wyoming' },
];

export const CARIBBEAN_US_STATES = [
  { value: 'PR', id: 'PR', text: 'Puerto Rico' },
  { value: 'VI', id: 'VI', text: 'U.S Virgin Islands' },
];

export const US_STATES_NO_HEADING = US_STATES.slice(1);

export const ALL_US_STATES_NO_HEADING = [
  ...US_STATES_NO_HEADING,
  ...CARIBBEAN_US_STATES,
].sort((a, b) => (a.value > b.value ? 1 : -1));

export const ALL_US_STATES = [US_STATES[0], ...ALL_US_STATES_NO_HEADING];

export const ERROR_TITLE = {
  DEFAULT: 'Error',
  DOB: 'Invalid Date of Birth',
  INVALID_STATE: 'Invalid U.S. State',
  INVALID_ADDRESS: 'Invalid Address',
  INVALID_UNIQUE_ID: 'Member details not found',
  HEALTH_PLAN_INFORMATION_ERROR: 'We are having trouble processing your order.',
  PROGRAM_NAME_NOT_FOUND: 'Program name not found',
};

export const ERROR_CONTENT = {
  DEFAULT: 'Please try again or contact our support team.',
  DOB: 'You do not meet minimum age requirements for this Everlywell at home test. Please reach out to your administrator.',
  INVALID_STATE:
    'Order failed. This product is not available in the state of New York. Please reach out to your administrator.',
  INVALID_UNIQUE_ID: `If you need help, please call Everlywell <a style='text-decoration:none' href='tel:${EF_MEMBERS_SUPPORT_PHONE_NUMBER}'>${EF_MEMBERS_SUPPORT_PHONE_NUMBER}</a>`,
  HEALTH_PLAN_INFORMATION_ERROR: `For support, please contact the EverlyWell Member Support Team team at ${EF_MEMBERS_SUPPORT_PHONE_NUMBER} (TTY: 711) or ${EF_MEMBERS_SUPPORT_FORM}.`,
  UNAVAILABLE_TESTS: `We’re sorry, there are no kits available for you to claim. If you need help, please call Everlywell at <var support-phone-number>${EF_MEMBERS_SUPPORT_PHONE_NUMBER}</var>.`,
};

export const ERROR_REGEX = {
  INVALID_STATE:
    /For regulatory reasons, our tests are not available in (?<state>[A-Z][a-z]+(?: +[a-z]+)?(?: +[A-Z][a-z]+)*) for (?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!]{2,}. If you believe this is an error, please check your zipcode./,
  INVALID_STATE_VARIANT:
    /Invalid product ID: (?:\d+) is unavailable in (?<state>[A-Z][a-z]+(?: +[A-Z][a-z]+)*)/,
};

export const OVERNIGHT_SHIPPING = 12;
export const STANDARD_SHIPPING = 7;

export const OVERNIGHT_SHIPPING_MAX_ORDERABLE = 6;

export const COVID_19_BASE_SKU = /covid-19/;
export const COVID_19_SKU_EXCEPTION = /covid-19-antibody/;
export const COVID_19_ID = 96;

export const STRIPE = {
  CARD_NUMBER_INPUT: 'card-number-stripe-input',
  EXPIRATION_DATE_INPUT: 'expiration-date-stripe-input',
  CVC_INPUT: 'cvc-stripe-input',
};

export const HUMANA_STATIC_CONTENT = {
  LANDING_PAGE_HEADLINE:
    'Humana has partnered with Everlywell to offer at-home lab testing kits, shipped directly to your doorstep at no cost to you.',
  LANDING_PAGE_BASIC_DETAILS:
    'This will enable you to complete this essential screening, such as Fecal Immunochemical Testing (FIT) for colorectal cancer, a urine test to assess kidney function, or HbA1c testing for blood glucose levels, without having to spend the additional time and effort of going into the lab.',
  LANDING_PAGE_MORE_DETAILS:
    'Everlywell works with labs certified by the Clinical Labratory Improvement Amendments (CLIA). Each set of test results is reviewed by a board-certified doctor in your state before being sent back to you.',
  LANDING_PAGE_TEST_NOT_ORDERED:
    "Did you receive an at-home test you didn't order? That means Humana's records indicate that you may be due for a screening. Humana has teamed up with Everlywell to send you an at-home test at no additional cost to you. We didn't want you to miss out.",
  EVERLYWELL_PHONE_NUMBER: '855-923-2678',
  EVERLYWELL_PHONE_NUMBER_MODE: 'TTY: 711',
  EVERLYWELL_AVAILABILITY: '8 AM - 8 PM EST',
  EW_RESULTS_URL:
    'https://secure.everlywell.com/members/login?healthplan_slug=humana',
};

export const HUMANA_STATIC_FOOTER = {
  FOOTER_TEXT:
    'Everlywell offers easy at-home lab test kits and provide insightful results directly to you.',
  FOOTER_DETAILS:
    'The tests provide personalized information and education; tests are not intended to be used for diagnostic purposes. Everlywell works with a doctor network to ensure that your test requisition is reviewed and approved by an independent board-certified doctor in your state and that your results are reviewed by a doctor before you receive them.',
  FOOTER_IMPORTANT_DISCLAIMER:
    'It is important that we treat you fairly. Discrimination is against the law. Humana Inc. and its subsidiaries do not discriminate or exclude people because of their race, color, national origin, age, disability, sex, sexual orientation, gender identity, or religion. Humana complies with all Federal and State Civil Rights laws.',
  FOOTER_ENGLISH:
    'Language assistance services, free of charge, are available to you. Call 1-855-923-2678 (TTY: 711).',
  FOOTER_SPANISH:
    'Español: Llame al número arriba indicado para recibir servicios gratuitos de asistencia lingüística.',
  FOOTER_MANDARIN: '繁體中文: 撥打上面的電話號碼即可獲得免費語言援助服務。',
};

export const HUMANA_STATIC_FOOTER_ADDRESS = {
  COMPANY_NAME: 'Humana Inc.',
  COMPANY_ADDRESS: '500 West Main St',
  COMPANY_ADDRESS_SEC_LINE: 'Louisville, KY 40202',
  FOOTER_ID: 'Y0040_GNHKTUFEN_C',
  LAST_UPDATED: 'Last updated: 07/02/2020',
};
