/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import { analytics, initializeDatadog } from 'utils/helpers';
import '@everlywell/leaves/lib/style.css';

type LocationType = {
  href: string;
  search: string;
  pathname: string;
}

type UrlDataType = {
  previousPath?: string;
  title?: string;
  referrer?: string;
  url?: string;
  search?: string;
  path?: string;
}

function addFont() {
  // add EW Nexa fonts
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = 'https://cdn.everlywell.com/fonts/ew-font.css';
  document.head.appendChild(link);
}

// load the font before rendering the application to avoid a flicker
const onClientEntry = () => {
  addFont();
  initializeDatadog();
};

const getCustomData = () => {
  const customDataElem: NodeListOf<HTMLElement> = document.querySelectorAll('[data-track-custom-data]')

  if (customDataElem.length === 0) return {};

  const data = Array
    .from(customDataElem)
    .reduce((customData, currentElem) => {
      const currentCustomData = JSON.parse(currentElem?.dataset?.trackCustomData ?? '')

      return (customData = { ...customData, ...currentCustomData });
    }, {});

  return data;
};


const urlTrackedData = (location: LocationType, prevLocation: Partial<LocationType>) => {
  let urlData: UrlDataType = {};

  const previousPath = prevLocation ? prevLocation.pathname : null;
  if (previousPath) urlData.previousPath = previousPath;

  if (document.title) urlData.title = document.title;
  if (document.referrer) urlData.referrer = document.referrer;
  if (location.href) urlData.url = location.href;
  if (location.search) urlData.search = location.search;
  if (location.pathname) urlData.path = location.pathname;

  return { ...urlData, ...getCustomData() };
};

// Logs when the client route changes
const onRouteUpdate = ({ location, prevLocation }: { location: LocationType, prevLocation: Partial<LocationType> }) => {
  analytics.track('Page Viewed', urlTrackedData(location, prevLocation));
};

export { onClientEntry, onRouteUpdate };
export { wrapRootElement } from './src/apollo/client.js';
