import supportIcon from 'assets/images/support.svg';
import React from 'react';
import { SUPPORT_ROOT } from 'utils/constants';

const SupportLink = () => {
  return (
    <a
      className="support-link"
      aria-label="Support"
      href={SUPPORT_ROOT}
      target="_blank"
      rel="noreferrer"
    >
      <img alt="Support" src={supportIcon} />
    </a>
  );
};

export default SupportLink;
