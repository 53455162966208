import { CheckIcon } from 'components/icons';
import React from 'react';

import * as S from './styles';

import { WizardProgressBarProps } from './';

interface ProgressBarStepProps extends Omit<WizardProgressBarProps, 'steps'> {
  index: number;
  label: string;
  accomplished: boolean;
}

const ProgressBarStep: React.FC<ProgressBarStepProps> = ({
  accomplished,
  index,
  label,
  currentStep,
  goToStep,
}) => {
  const isStepSuccessfullyCompleted = accomplished && index < currentStep;

  const navigationProps = isStepSuccessfullyCompleted
    ? {
      onClick: () => goToStep(index),
    }
    : {};

  const className = `${accomplished ? 'accomplished' : null}`;

  return (
    <>
      <S.StepLabel className={className} {...navigationProps}>
        {label}
      </S.StepLabel>
      <S.StepCircle className={className} {...navigationProps}>
        {isStepSuccessfullyCompleted ? <CheckIcon /> : index + 1}
      </S.StepCircle>
    </>
  );
};

export default ProgressBarStep;
