import { useHooks } from '@everlywell/leaves';
import React from 'react';

import Container from './Container';
import { WizardProvider } from './context';
import { Props, ChildProps } from './types';

const { useStep } = useHooks;

const Wizard = <T,>({ steps, children, initialState }: Props<T>) => {
  const { currentStep, navigation } = useStep({ steps });

  return (
    <WizardProvider initialState={initialState}>
      <Container stepProps={{ steps, currentStep, navigation }}>
        {React.Children.map(children, (child, stepIndex) => (
          <div hidden={stepIndex !== currentStep}>
            {(React.isValidElement(child)) && React.cloneElement(child, {
              navigation,
              stepIndex,
              currentStep,
              isCurrentStep: stepIndex === currentStep,
            } as ChildProps)}
          </div>
        ))}
      </Container>
    </WizardProvider>
  );
};

export default Wizard;
