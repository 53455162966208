import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

/**
 * Datadog Logging initialization method.
 */
export function initializeDatadog(
  site = 'datadoghq.com',
  service = 'opt-in-portal',
) {
  const ddConfig = {
    site,
    service,
    clientToken: process.env.GATSBY_DATADOG_CLIENT_TOKEN || '',
    env: process.env.GATSBY_REACT_APP_ENV,
    sampleRate: 100,
    silentMultipleInit: true,
  };

  datadogLogs.init({
    ...ddConfig,
    forwardErrorsToLogs: true,
    telemetrySampleRate: 0,
  });

  datadogRum.init({
    ...ddConfig,
    applicationId: process.env.GATSBY_DATADOG_APPLICATION_ID || '',
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
  });
}

/* */

/**
 * Logs an event to Datadog. By default it will log an error event.
 * @param {*} message
 * @param {*} context
 */
export function logError(message: string, context = {}) {
  datadogLogs.logger.error(message, context);
}

/**
 * Logs an info event to Datadog.
 * @param {*} message
 * @param {*} context
 */
export function logInfo(message: string, context = {}) {
  datadogLogs.logger.info(message, context);
}
