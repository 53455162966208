import { Error } from '@everlywell/leaves';
import React from 'react';

import * as S from './styles';

interface Props {
  title: string;
  content: string;
}

const Toast: React.FC<Props> = ({ title, content }: Props) => {
  return (
    <S.Container>
      <S.IconWrapper>
        <Error />
      </S.IconWrapper>
      <S.Column>
        <S.Title>{title}</S.Title>
        <S.Content dangerouslySetInnerHTML={{ __html: content }} />
      </S.Column>
    </S.Container>
  );
};

export default Toast;
