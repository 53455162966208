
import React from 'react';
import * as S from './styles';
import { ButtonProps } from '@everlywell/leaves';

interface Props extends ButtonProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  isLoading?: boolean;
  isDisabled?: boolean;
}

const BaseButton: React.FC<Props> = ({
  children,
  className,
  style,
  isLoading,
  isDisabled,
  ...rest
}: Props) => {
  return (
    <S.Button
      className={className}
      style={style}
      isLoading={isLoading}
      isDisabled={isLoading || isDisabled}
      {...rest}
    >
      {isLoading ? <div className="loader" /> : children}
    </S.Button>
  );
};

export default BaseButton;
