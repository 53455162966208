import IconClose from 'components/icons/modal-close';
import React from 'react';
import { Slide } from 'react-toastify';

import * as S from './styles';
import 'react-toastify/dist/ReactToastify.css';

const ToastContainer: React.FC = () => {
  return (
    <S.ToastContainer
      autoClose={8000}
      transition={Slide}
      closeButton={IconClose}
    />
  );
};

export default ToastContainer;
