import React, { createContext, useReducer } from 'react';
import { Action, Context, ProviderProps } from './types';

const WizardContext = createContext<Context<any>>(null!);
const { Provider } = WizardContext;

const reducer = <T,>(state: T, action: Action<T>): T => {
  switch (action.type) {
    case 'updateData':
      return { ...state, ...action.data };
    default:
      throw new Error();
  }
};

const WizardProvider = <T,>({ children, initialState }: ProviderProps<T>) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch } as Context<T>;

  return <Provider value={value}>{children}</Provider>;
};

export { WizardContext, WizardProvider };
