import { ProgressBar } from '@everlywell/leaves';
import React from 'react';

import * as S from './styles';
import { ContainerProps } from '../types';

const WizardContainer = ({ children, stepProps }: ContainerProps) => {
  const {
    navigation: { goToStep },
  } = stepProps;

  return (
    <S.Card>
      <S.ProgressBarContainer>
        <ProgressBar hasTopLabels={true} goToStep={goToStep} {...stepProps} />
      </S.ProgressBarContainer>
      {children}
    </S.Card>
  );
};

export default WizardContainer;
