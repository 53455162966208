import { colors, size } from '@everlywell/leaves';
import { ToastContainer as Container } from 'react-toastify';
import styled from 'styled-components';

const ToastContainer = styled(Container)`
  top: 7em;
  transform: unset;
  left: calc(50% - 297px);

  .Toastify__toast {
    width: 594px;
    padding: ${size.sm}px;
    border-radius: 1px;
    box-shadow: 0 2px 20px -5px rgba(170, 169, 172, 0.4);
    background-color: white;

    @media (max-width: 786px) {
      width: 343px;
      left: calc(100% - 60%);
    }
  }

  .Toastify__toast--error {
    background-color: ${colors.red1};
  }

  .Toastify__progress-bar--error {
    background-color: ${colors.red3};
  }
`;

export { ToastContainer }
