import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { logError } from 'utils/helpers';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const partnerID = process.env.GATSBY_ENTERPRISE_PARTNER_ID;

  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      logError(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}.`,
        { details: { partnerID } },
      );
    });

  if (networkError)
    logError(`[Network error]: ${networkError}`, { details: { partnerID } });
});

const httpLink = createHttpLink({
  uri: process.env.GATSBY_HASURA_BASE_URL,
});

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  cache: new InMemoryCache(),
});

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
);
