import React from 'react';
import { ProgressBar, Step } from 'react-step-progress-bar';

import ProgressBarStep from './ProgressBarStep';
import * as S from './styles';

export interface WizardProgressBarProps {
  steps: string[];
  currentStep: number;
  goToStep: (newStep: number) => void;
}

const WizardProgressBar = ({ steps, currentStep, goToStep }: WizardProgressBarProps) => {
  const stepCount = 100 / (steps.length - 1);
  const percent = currentStep > 0 ? currentStep * stepCount : currentStep;

  return (
    <S.Wrapper>
      <ProgressBar percent={percent}>
        {steps.map((step) => (
          <Step key={step}>
            {({ accomplished, index }) => (
              <ProgressBarStep
                index={index}
                label={step}
                accomplished={accomplished}
                currentStep={currentStep}
                goToStep={goToStep}
              />
            )}
          </Step>
        ))}
      </ProgressBar>
    </S.Wrapper>
  );
};

export default WizardProgressBar;
