import React from 'react'

import * as S from './styles'

interface Props {
  isHidden?: boolean;
  spacing?: string;
}

const Divider: React.FC<Props> = ({ isHidden, spacing }: Props) => {
  return (<S.Divider isHidden={isHidden} spacing={spacing} />)
}

export default Divider
