import styled from 'styled-components';
import { colors } from '@everlywell/leaves';

const Wrapper = styled.div`
  margin: 4.5rem auto 0;
  max-width: 18rem;
  height: 2.938rem;

  .RSPBprogressBar {
    height: 2px;
    line-height: 1;
    border-radius: 10px;
    position: relative;
    background-color: ${colors.green2};
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
    margin: 20px;
  }

  .RSPBprogressBar .RSPBstep {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    position: absolute;
    transform: translateX(-50%);
    transition-property: all;
    transition-timing-function: ease;
  }

  .RSPBprogressBar .RSPBprogressBarText {
    color: white;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .RSPBprogressBar .RSPBprogression {
    position: absolute;
    transition: width 0.3s ease;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    background: ${colors.green4};
    z-index: -1;
  }
`;

const StepCircle = styled.div`
  color: white;
  width: 18px;
  height: 18px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  background-color: ${colors.green2};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.accomplished {
    cursor: pointer;
    background-color: ${colors.green4};
  }
`;

const StepLabel = styled.div`
  position: absolute;
  top: -25px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.57;
  text-align: center;
  color: ${colors.gray4};
  text-transform: capitalize;

  &.accomplished {
    cursor: pointer;
    font-weight: bold;
  }
`;

export { StepCircle, StepLabel, Wrapper };
