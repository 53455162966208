import React from 'react';
import { toast } from 'react-toastify';
import { Toast } from 'components/ui';

const displayError = (title: string, content: string = '') => {
  toast(<Toast title={title} content={content} />, {
    position: toast.POSITION.TOP_CENTER,
    type: 'error',
  });
};

export default displayError;
