import React from "react"

const ShipmentIcon = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="28" cy="28" r="28" fill="#EEF5F6" />
    <path d="M12.667 32H43.3337V35.3333C43.3337 36.4379 42.4382 37.3333 41.3337 37.3333H14.667C13.5624 37.3333 12.667 36.4379 12.667 35.3333V32Z" fill="#B8DDE1" />
    <path fillRule="evenodd" clipRule="evenodd" d="M41.4785 37.3327H14.5221C13.4973 37.3327 12.667 36.4771 12.667 35.4223V16.5745C12.667 15.5197 13.4973 14.666 14.5221 14.666H41.4785C42.5033 14.666 43.3337 15.5197 43.3337 16.5745V35.4223C43.3337 36.4771 42.5033 37.3327 41.4785 37.3327Z" stroke="#007377" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M28.2114 35.1608C28.2114 35.3763 28.0379 35.5512 27.8242 35.5512C27.6105 35.5512 27.4369 35.3763 27.4369 35.1608C27.4369 34.9443 27.6105 34.7693 27.8242 34.7693C28.0379 34.7693 28.2114 34.9443 28.2114 35.1608Z" stroke="#007377" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M32.2235 42.7728H24L24.8765 37.4395H31.3471L32.2235 42.7728Z" fill="#B8DDE1" stroke="#007377" strokeWidth="1.5" strokeLinejoin="round" />
    <path d="M12.6133 32.4044L43.0004 32.333" stroke="#007377" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22 42.6654H34" stroke="#007377" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M24.6013 21.8249H22.1418V19.334H18.4595V21.8249H16V25.5542H18.4595V28.0451H22.1418V25.5542H24.6013V21.8249Z" fill="#B8DDE1" stroke="#007377" strokeWidth="1.5" strokeLinejoin="round" />
    <path d="M28 27.334L31.7052 23.5551L35.3918 26.7277L37.384 24.3959L40 21.334" stroke="#007377" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M38 21.334H40V24.0007" stroke="#007377" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default ShipmentIcon
