import { size, colors } from '@everlywell/leaves';
import styled from "styled-components";

interface DividerStyleProps {
  readonly spacing?: string;
  readonly isHidden?: boolean;
}

const Divider = styled.hr<DividerStyleProps>`
  max-width: 270px;
  margin: ${props => props.spacing ?? `${size.xl1}px`} auto;
  border: 0;
  border-top: 1px solid ${props => props.isHidden ? 'transparent' : colors.teal2};
`

export { Divider }
