import { ERROR_TITLE, ERROR_CONTENT, ERROR_REGEX } from 'utils/constants';

/**
 * ['Program not Found']
 *
 * @param {Array} errors An Array of errors message
 */
const parseErrorsMessage = (errors: string[]) => {
  let [title, content] = [ERROR_TITLE.DEFAULT, ERROR_CONTENT.DEFAULT];

  if (errors?.length === 0) return [title, content];

  if (errors.some((el) => el.includes('Birthday is invalid'))) {
    [title, content] = getInvalidDOBMessage();
  } else if (errors.some((el) => el.includes('tests are not available'))) {
    [title, content] = getTestsNotAvailableMessage(errors);
  } else if (errors.some((el) => el.includes('Invalid product ID'))) {
    [title, content] = getInvalidProductMessage(errors);
  } else if (errors.some((el) => el.includes('Program Not Found'))) {
    [title, content] = getProgramNotFoundMessage();
  }

  return [title, content];
};

function getInvalidProductMessage(errors: string[]) {
  let message = errors.find((el) => el.includes('Invalid product ID')) ?? '';
  let content = getNotAvailableMessageContent(
    message,
    ERROR_REGEX.INVALID_STATE_VARIANT,
  );

  return [ERROR_TITLE.INVALID_ADDRESS, content];
}

function getTestsNotAvailableMessage(errors: string[]) {
  let message = errors.find((el) => el.includes('tests are not available')) ?? '';
  let content = getNotAvailableMessageContent(
    message,
    ERROR_REGEX.INVALID_STATE,
  );

  return [ERROR_TITLE.INVALID_ADDRESS, content];
}

function getNotAvailableMessageContent(message: string, regex: RegExp) {
  const found = message.match(regex);
  const state = found?.groups?.state;

  return `This test is not available in ${state}. Please reach out to your administrator.`;
}

function getInvalidDOBMessage() {
  return [ERROR_TITLE.DOB, ERROR_CONTENT.DOB];
}

function getProgramNotFoundMessage() {
  return [
    ERROR_TITLE.PROGRAM_NAME_NOT_FOUND,
    ERROR_CONTENT.HEALTH_PLAN_INFORMATION_ERROR,
  ];
}

export default parseErrorsMessage;
